import React from "react";
import { createBrowserRouter } from "react-router-dom";
import EventDetail from "./Components/Events/EventDetail";
import AboutUs from "./Pages/About Us/AboutUs";
import ContactUs from "./Pages/Contact Us/ContactUs";
import Events from "./Pages/Events/Events";
import FoodMenuPage from "./Pages/FoodMenu/FoodMenuPage";
import Home from "./Pages/Home/Home";
import LocationDetail from "./Pages/Location/LocationDetail";
import LocationPage from "./Pages/Location/LocationPage";


export const routeObject = [
    {
        path: "/",
        name : "Home",
        element: <Home />,
        isMainMenu : true
    },
    {
        path: "/about-us",
        name : "About Us",
        element: <AboutUs />,
        isMainMenu : true
    },
    {
        path: "/menu",
        name : "Menu",
        element: <FoodMenuPage />,
        isMainMenu : true
    },
    {
        path: "/events",
        name : "Events",
        element: <Events />,
        isMainMenu : true
    },
    {
        path: "/events/:eventId",
        name : "Event Detail",
        element: <EventDetail />,
        isMainMenu : false
    },
    {
        path: "/location",
        name : "Location",
        element: <LocationPage />,
        isMainMenu : true
    },
    {
        path : "/location/:locationId",
        name : "Location Detail",
        element : <LocationDetail />,
        isMainMenu : false
    },
    {
        path: "/contact-us",
        name : "Contact Us",
        element: <ContactUs />,
        isMainMenu : true
    },
]

const router = createBrowserRouter(routeObject);

export default router;
