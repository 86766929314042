import React from "react";
import fb from "../../Assets/footer-fb.png";
import tw from "../../Assets/footer-tw.png";
import ig from "../../Assets/footer-ig.png";

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer_main">
                <div className="footer_contact_us">
                    <h6>Contact Us</h6>
                    <p>+251 909-344-1230</p>
                    <p>+251 909-555-1230</p>
                </div>
                <div className="footer_social_media">
                    <p className="quote">"The best way to find yourself is to lose yourself in the service of others.”</p>
                    <div className="links">
                        <a href="#">
                            <img src={fb} />
                        </a>
                        <a href="#">
                            <img src={tw} />
                        </a>
                        <a href="#">
                            <img src={ig} />
                        </a>
                    </div>
                </div>
                <div className="footer_working_hours">
                    <h6>Working Hours</h6>
                    <p>Monday-Friday: <br></br>07:00 am - 12:00 am</p>
                    <p>Monday-Friday: <br></br>07:00 am - 12:00 am</p>
                </div>
            </div>
            <div className="footer_copyright">
                <p>2022 Bitapps Technologies . All Rights reserved.</p>
            </div>
        </div>
    );
}
