import React, { useEffect, useRef, useState } from "react";

import ReactPlayer from "react-player";

import vid from "../../Assets/about-us-vid.mp4";
import vid_poster from "../../Assets/about-us-vid-poster.png";

export default function AboutUsVideo() {
    const aboutUsVid = useRef();

    const [isPlaying, setIsPlaying] = useState(true);

    useEffect(() => {
        isPlaying ? aboutUsVid.current.pause() : aboutUsVid.current.play();
    }, [isPlaying]);

    return (
        <div className={`about_us_vid_section ${!isPlaying ? "playing" : ""} `}>
            <button className="pause_play" onClick={() => setIsPlaying(!isPlaying)}>
                Play
            </button>

            <video
                ref={aboutUsVid}
                height="760px"
                width="100%"
                controls
                poster={vid_poster}
                preload="metadata"
                autoPlay={false}
                onPlay={() => setIsPlaying(false)}
                onPause={() => setIsPlaying(true)}
            >
                <source src={vid} type="video/mp4"></source>
            </video>
        </div>
    );
}
