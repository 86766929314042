import React from 'react'
import InteriorPageHeader from '../../Components/Common/InteriorPageHeader'
import header_bg from "../../Assets/about-us-header-bg.png";
import AboutUsHistory from './AboutUsHistory';
import AboutUsVideo from './AboutUsVideo';

import MiniEventList from '../../Components/Events/MiniEventList';
import Footer from '../../Components/Footer/Footer';
import FooterLocation from '../../Components/FooterLocation/FooterLocation';
import Testimonials from '../../Components/Testimonials/Testimonials';

export default function AboutUs() {
  return (
    <div className='about_us'>
      <InteriorPageHeader text={"About Us"} img={header_bg} />
      <AboutUsHistory />
      <AboutUsVideo />
      <MiniEventList title={"Chaka Updates"}/>
      <Testimonials />
      <FooterLocation />
      <Footer />
    </div>
  )
}
