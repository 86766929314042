import React from "react";

import vid from "../../Assets/loading-video-2.mp4";

export default function LoaderVideo() {
    return (
        <div className="video">
            <video id="player" autoPlay loop={false} muted>
                <source id="source1" src={vid} type="video/mp4" />
            </video>
        </div>
    );
}
