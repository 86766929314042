import React, { useState } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

const locations = [
    {
        name: "Chichinya Hayahulet | ቺቺኒያ ሃያሁለት",
        latLong: { lat: 9.011539550354458, lng: 38.78096456037669 },
    },
    {
        name: "Bole Friendship | ቦሌ ፍሬንድሺፕ, Addis Ababa, Ethiopia",
        latLong: { lat: 8.988725426965125, lng: 38.78530499902054 },
    },
    {
        name: "Meskel Flower | መስቀል ፍላወር, Addis Ababa, Ethiopia",
        latLong: { lat: 8.993726416626597, lng: 38.766771772625525 },
    },
    {
        name: "Stadium | ስታድየም, Addis Ababa, Ethiopia",
        latLong: { lat: 9.011539550354458, lng: 38.78096456037669 },
    },
    {
        name: "Morning Star Mall| ሞርኒንግ ስታር ጀርባ",
        latLong: { lat: 8.996593408494567, lng: 38.78660044195444 },
    },
    {
        name: "Ras Shell | ራስ ሼል",
        latLong: { lat: 9.014237655518514, lng: 38.7538998655554 },
    },
];

export default function FooterLocation() {
    const [currentCoordinate, setCurrentCoordinate] = useState(locations[0].latLong);

    const firstThree = locations.slice(0, 3).map((item, i) => {
        return (
            <li key={i}>
                <button className="location_button" onClick={() => setCurrentCoordinate(item.latLong)}>
                    {item.name}
                </button>
            </li>
        );
    });

    const lastThree = locations.slice(3).map((item, i) => {
        return (
            <li key={i}>
                <button className="location_button" onClick={() => setCurrentCoordinate(item.latLong)}>
                    {item.name}
                </button>
            </li>
        );
    });

    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    if (!isLoaded) {
        return <div>Loading ...</div>;
    }

    console.log(loadError);

    return (
        <div className="footer_location">
            <div className="title">
                <h2>Visit Us</h2>
            </div>
            <div className="map_wrapper">
                <div className="buttons buttons_left">
                    <ul>{firstThree}</ul>
                </div>
                {!loadError && (
                    <GoogleMap zoom={18} center={currentCoordinate} mapContainerClassName="map-container">
                        <MarkerF position={currentCoordinate} />
                    </GoogleMap>
                )}

                <div className="buttons buttons_right">
                    <ul>{lastThree}</ul>
                </div>
            </div>
        </div>
    );
}
