import React from "react";


import img_2 from "../../Assets/about-us-img-1.png";
import img_1 from "../../Assets/about-us-img-2.png";

export default function AboutUsHistory() {
    return (
        <div className="about_us_history">
            <div className="title">
                <h2>serving customers for over a decade</h2>
                <p>Our History</p>
            </div>
            <div className="history">
                <p className="short_history">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis pharetra adipiscing ultrices vulputate posuere tristique.
                    In sed odio nec aliquet eu proin mauris et. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis pharetra
                    adipiscing ultrices vulputate posuere tristique. In sed odio nec aliquet eu proin mauris et.
                </p>
                <img className="img-1" src={img_1} />
                <div className="stats">
                    <h4>Over The Years</h4>
                    <div className="data">
                        <div className="stat">
                            <p className="stat_num">30+</p>
                            <p className="stat_desc">Breakfast Options</p>
                        </div>
                        <div className="stat">
                            <p className="stat_num">50+</p>
                            <p className="stat_desc">Dinner Options</p>
                        </div>
                        <div className="stat">
                            <p className="stat_num">7</p>
                            <p className="stat_desc">New Locations</p>
                        </div>
                    </div>
                </div>
                <img className="img-2" src={img_2} />
            </div>
        </div>
    );
}
