import { RouterProvider } from "react-router-dom";
import router from "./router";

import MainMenu from "./Components/MainMenu/MainMenu.jsx";
import LoaderVideo from "./Components/LoaderVideo/LoaderVideo";
import { useState } from "react";

function App() {
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [isContentDisplayed, setIsContentDisplayed] = useState(true);

    setTimeout(() => {
        //setIsVideoPlaying(false);
    }, 8000);

    setTimeout(() => {
        //setIsContentDisplayed(true);
    }, 6000);


    return (
        <div className="App">
            {isVideoPlaying && <LoaderVideo />}

            {isContentDisplayed && (
                <>
                    <MainMenu />
                    <div className="main-container">
                        <RouterProvider router={router} />
                    </div>
                </>
            )}
        </div>
    );
}

export default App;
