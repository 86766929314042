import React from "react";
import { Carousel } from "react-responsive-carousel";

import img_1 from "../../Assets/food-img-3-1.png";
import img_2 from "../../Assets/food-img-3-2.png";
import img_3 from "../../Assets/food-img-3-3.png";
import img_4 from "../../Assets/food-img-3-4.png";

const images = [img_1, img_2, img_3, img_4];

const items = images.map((img, i) => {
    return (
        <div className={`item item_${i + 1}`}>
            <img src={img} />
            <div className="content">
                <p className="item_title">YECHAKA SPECIAL OMELET</p>
                <p className="item_description">Lorem ipsum dolor sit amet consectetur. Volutpat elit lectus porta volutpat quisque.</p>
            </div>
        </div>
    );
});

export default function HomeSpecialties() {
    return (
        <div className="home_specialties">
            <div className="title">
                <h2>Specialties</h2>
            </div>
            <div className="items">{items}</div>
        </div>
    );
}
