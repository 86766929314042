import React from "react";
import header_bg from "../../Assets/location-header-bg.png";
import InteriorPageHeader from "../../Components/Common/InteriorPageHeader";
import img_1 from "../../Assets/update-1.png";
import LocationItem from "./LocationItem";
import MiniEventList from "../../Components/Events/MiniEventList";
import FooterLocation from "../../Components/FooterLocation/FooterLocation";
import Footer from "../../Components/Footer/Footer";

const locations = [
    {
        id: 1,
        img: img_1,
        name: "Africa avenu – Next to jupiter hotel – Breakfast, restaurant",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
    },
    {
        id: 2,
        img: img_1,
        name: "Africa avenu – Next to jupiter hotel – Breakfast, restaurant",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
    },
    {
        id: 3,
        img: img_1,
        name: "Africa avenu – Next to jupiter hotel – Breakfast, restaurant",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
    },
    {
        id: 1,
        img: img_1,
        name: "Africa avenu – Next to jupiter hotel – Breakfast, restaurant",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
    },
    {
        id: 2,
        img: img_1,
        name: "Africa avenu – Next to jupiter hotel – Breakfast, restaurant",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
    },
    {
        id: 3,
        img: img_1,
        name: "Africa avenu – Next to jupiter hotel – Breakfast, restaurant",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
    },
];
        

export default function LocationPage() {
    return (
        <div className="location_page">
            <InteriorPageHeader text={"Locations"} img={header_bg} />
            <div className="main_content">
                <div className="title">
                    <h2>serving customers for over a decade</h2>
                    <p>Our History</p>
                </div>
                <div className="location_list">
                    {locations.map((l,i) => {
                        return (<LocationItem className={`${i%2 != 0 ? 'lc_odd' : ''}`} {...l} key={i} />)
                    })}
                </div>
            </div>
            <MiniEventList title={"Chaka Updates"}/>
            <FooterLocation />
            <Footer />
            
        </div>
    );
}
