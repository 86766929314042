import React from 'react'

export default function InteriorPageHeader({text, img}) {
  return (
    <div className='interior_page_header'>
        <img src={img} />
        <h1>{text}</h1>
    </div>
  )
}
