import React, { useState } from "react";
import InteriorPageHeader from "../../Components/Common/InteriorPageHeader";
import header_bg from "../../Assets/menu-page-header-bg.png";

import item_1 from "../../Assets/menu-item-1.png";
import item_2 from "../../Assets/menu-item-2.png";
import item_3 from "../../Assets/menu-item-3.png";
import item_4 from "../../Assets/menu-item-4.png";
import item_5 from "../../Assets/menu-item-5.png";
import item_6 from "../../Assets/menu-item-6.png";
import FooterLocation from "../../Components/FooterLocation/FooterLocation";
import Footer from "../../Components/Footer/Footer";

const locations = [
    {
        name: "Chichinya Hayahulet | ቺቺኒያ ሃያሁለት",
        latLong: { lat: 9.011539550354458, lng: 38.78096456037669 },
    },
    {
        name: "Bole Friendship | ቦሌ ፍሬንድሺፕ",
        latLong: { lat: 8.988725426965125, lng: 38.78530499902054 },
    },
    {
        name: "Meskel Flower | መስቀል ፍላወር",
        latLong: { lat: 8.993726416626597, lng: 38.766771772625525 },
    },
    {
        name: "Stadium | ስታድየም",
        latLong: { lat: 9.011539550354458, lng: 38.78096456037669 },
    },
    {
        name: "Morning Star Mall | ሞርኒንግ ስታር ጀርባ",
        latLong: { lat: 8.996593408494567, lng: 38.78660044195444 },
    },
    {
        name: "Ras Shell | ራስ ሼል",
        latLong: { lat: 9.014237655518514, lng: 38.7538998655554 },
    },
];

const images = [
    item_1,
    item_2,
    item_3,
    item_4,
    item_5,
    item_6,
    item_1,
    item_2,
    item_3,
    item_4,
    item_5,
    item_6,
    item_1,
    item_2,
    item_3,
    item_4,
    item_5,
    item_6,
];

const items = [...Array(18).keys()].map((i) => {
    return (
        <div key={i} className="item">
            <img src={images[i]} />
            <div className="content">
                <p className="content_title">YECHAKA SPECIAL OMELET</p>
                <p className="description">Lorem ipsum dolor sit amet consectetur. Volutpat elit lectus porta volutpat quisque.</p>
            </div>
        </div>
    );
});

console.log(items.length)

export default function FoodMenuPage() {

  const [currentLocationId, setCurrentLocationId] = useState(0);



    return (
        <div className="menu_page">
            <InteriorPageHeader text="Menu" img={header_bg} />
            <div className="main_content">
                <div className="search_filter">
                    <div className="search_bar_wrapper">
                        <input className="search_bar" placeholder="Search" />
                    </div>
                    <div className="location_filter">
                        {locations.map((loc, i) => {
                            return (
                                <a href="#" key={i} className={i == currentLocationId ? "active" : ""} onClick={() => setCurrentLocationId(i)}>
                                    {loc.name}
                                </a>
                            );
                        })}
                    </div>
                </div>
                <div className="menu_list">
                    <div className="meal_filter">
                        <ul>
                            <li><a href="#">Breakfast</a></li>
                            <li><a href="#">Lunch</a></li>
                            <li><a href="#">Deserts</a></li>
                            <li><a href="#">Drinks</a></li>
                            <li><a href="#">Alcohol</a></li>
                        </ul>
                    </div>
                    <div className="item_list">
                      {items}
                    </div>
                </div>
            </div>
            <FooterLocation />
            <Footer />
        </div>
    );
}
