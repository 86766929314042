import React from "react";
import moment from "moment/moment";

import img_1 from "../../Assets/update-1.png";
import img_2 from "../../Assets/update-2.png";
import img_3 from "../../Assets/update-3.png";
import InteriorPageHeader from "../../Components/Common/InteriorPageHeader";
import header_bg from "../../Assets/events-page-header-bg.png";
import EventItem from "../../Components/Events/EventItem";
import FooterLocation from "../../Components/FooterLocation/FooterLocation";
import Footer from "../../Components/Footer/Footer";
import Button from "../../Components/Common/Button";

const data = [
    {
        id: 1,
        img: img_1,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment(),
        endDate: moment(),
    },
    {
        id: 2,
        img: img_2,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment().add(100, "day"),
        endDate: moment(),
    },
    {
        id: 3,
        img: img_3,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment().add(299, "day"),
        endDate: moment(),
    },
    {
        id: 1,
        img: img_1,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment(),
        endDate: moment(),
    },
    {
        id: 2,
        img: img_2,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment().add(100, "day"),
        endDate: moment(),
    },
    {
        id: 3,
        img: img_3,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment().add(299, "day"),
        endDate: moment(),
    },
    {
        id: 1,
        img: img_1,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment(),
        endDate: moment(),
    },
    {
        id: 2,
        img: img_2,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment().add(100, "day"),
        endDate: moment(),
    },
    {
        id: 3,
        img: img_3,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment().add(299, "day"),
        endDate: moment(),
    },
    {
        id: 1,
        img: img_1,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment(),
        endDate: moment(),
    },
    {
        id: 2,
        img: img_2,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment().add(100, "day"),
        endDate: moment(),
    },
    {
        id: 3,
        img: img_3,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate: moment().add(299, "day"),
        endDate: moment(),
    },
];

export default function Events() {
    return (
        <div className="events_page">
            <InteriorPageHeader text="Events" img={header_bg} />
            <div className="main_content">
                <div className="title">
                    <h2>Upcoming Events</h2>
                </div>
                <div className="search_events">
                    <div className="search_bar_wrapper">
                        <input className="search_bar" placeholder="Search" />
                    </div>

                    <select>
                        <option defaultValue={true} value="Weekdays">
                            Weekdays
                        </option>
                        <option value="Weekends">Weekends</option>
                    </select>
                </div>
                <div className="items">
                    {data.map((event, i) => {
                        return <EventItem key={i} {...event} />;
                    })}
                </div>
                <div className="button_wrapper">
                    <Button text={"Show More"} method={() => {}} />
                </div>
            </div>
            <FooterLocation />
            <Footer />
        </div>
    );
}
