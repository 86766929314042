import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import { useParams } from "react-router-dom";
import MiniEventList from "./MiniEventList";
import Footer from "../Footer/Footer"

import img_1 from "../../Assets/update-1.png";
import img_2 from "../../Assets/update-2.png";
import img_3 from "../../Assets/update-3.png";
import Button from "../Common/Button";
import FooterLocation from "../FooterLocation/FooterLocation";

const data = [
    {
        id: 1,
        img: img_1,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        longDescription:
            "Lorem ipsum dolor sit amet consectetur. Suspendisse purus mauris pharetra neque nunc lobortis pellentesque. Commodo vel phasellus sed pellentesque curabitur nulla varius. Volutpat parturient non donec neque praesent neque ac odio. Mi eget vestibulum dignissim pellentesque urna odio platea arcu.Placerat amet in fringilla morbi praesent et odio tortor. Pellentesque diam purus sed faucibus a. Fames neque volutpat ut pulvinar viverra auctor eu morbi. Imperdiet id neque vestibulum turpis vivamus urna. Egestas sem morbi aliquam egestas at id turpis. Eget natoque sit pharetra velit eget proin nisi volutpat. Id nulla vitae sodales ultrices venenatis. Ornare sed viverra mauris massa mattis mattis magna aliquet. Arcu ut dolor enim nisl. Amet tellus rutrum varius et turpis semper sollicitudin ipsum. Sit diam eu risus lacus nec eros. Sodales sem tellus fermentum dui pellentesque lobortis senectus ac. Ornare ut ullamcorper viverra integer diam tempus tincidunt adipiscing. Massa est turpis dolor convallis at tortor urna bibendum vivamus.",
        startDate: moment(),
        endDate: moment(),
        location: {
            name: "Chichinya Hayahulet | ቺቺኒያ ሃያሁለት",
            latLong: { lat: 9.011539550354458, lng: 38.78096456037669 },
        },
    },
    {
        id: 2,
        img: img_2,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        longDescription:
            "Lorem ipsum dolor sit amet consectetur. Suspendisse purus mauris pharetra neque nunc lobortis pellentesque. Commodo vel phasellus sed pellentesque curabitur nulla varius. Volutpat parturient non donec neque praesent neque ac odio. Mi eget vestibulum dignissim pellentesque urna odio platea arcu.Placerat amet in fringilla morbi praesent et odio tortor. Pellentesque diam purus sed faucibus a. Fames neque volutpat ut pulvinar viverra auctor eu morbi. Imperdiet id neque vestibulum turpis vivamus urna. Egestas sem morbi aliquam egestas at id turpis. Eget natoque sit pharetra velit eget proin nisi volutpat. Id nulla vitae sodales ultrices venenatis. Ornare sed viverra mauris massa mattis mattis magna aliquet. Arcu ut dolor enim nisl. Amet tellus rutrum varius et turpis semper sollicitudin ipsum. Sit diam eu risus lacus nec eros. Sodales sem tellus fermentum dui pellentesque lobortis senectus ac. Ornare ut ullamcorper viverra integer diam tempus tincidunt adipiscing. Massa est turpis dolor convallis at tortor urna bibendum vivamus.",
        startDate: moment().add(100, "day"),
        endDate: moment(),
        location: {
            name: "Bole Friendship | ቦሌ ፍሬንድሺፕ, Addis Ababa, Ethiopia",
            latLong: { lat: 8.988725426965125, lng: 38.78530499902054 },
        },
    },
    {
        id: 3,
        img: img_3,
        title: "Lorem ipsum dolor sit amet consectetur.",
        description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        longDescription:
            "Lorem ipsum dolor sit amet consectetur. Suspendisse purus mauris pharetra neque nunc lobortis pellentesque. Commodo vel phasellus sed pellentesque curabitur nulla varius. Volutpat parturient non donec neque praesent neque ac odio. Mi eget vestibulum dignissim pellentesque urna odio platea arcu.Placerat amet in fringilla morbi praesent et odio tortor. Pellentesque diam purus sed faucibus a. Fames neque volutpat ut pulvinar viverra auctor eu morbi. Imperdiet id neque vestibulum turpis vivamus urna. Egestas sem morbi aliquam egestas at id turpis. Eget natoque sit pharetra velit eget proin nisi volutpat. Id nulla vitae sodales ultrices venenatis. Ornare sed viverra mauris massa mattis mattis magna aliquet. Arcu ut dolor enim nisl. Amet tellus rutrum varius et turpis semper sollicitudin ipsum. Sit diam eu risus lacus nec eros. Sodales sem tellus fermentum dui pellentesque lobortis senectus ac. Ornare ut ullamcorper viverra integer diam tempus tincidunt adipiscing. Massa est turpis dolor convallis at tortor urna bibendum vivamus.",
        startDate: moment().add(299, "day"),
        endDate: moment(),
        location: {
            name: "Meskel Flower | መስቀል ፍላወር, Addis Ababa, Ethiopia",
            latLong: { lat: 8.993726416626597, lng: 38.766771772625525 },
        },
    },
];

export default function EventDetail() {
    let { eventId } = useParams();

    const [eventData, setEventData] = useState(data.filter((d) => d.id == eventId)[0]);
    const [currentCoordinate, setCurrentCoordinate] = useState(eventData.location.latLong);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
    });

    if (!isLoaded) {
        return <div>Loading ...</div>;
    }

    return (
        <div className="event_detail">
            <div className="header">
                <img src={eventData.img} />
                <div className="header_content">
                    <div className="general">
                        <h4>Chaka Events</h4>
                        <h1>{eventData.title}</h1>
                        <p>{eventData.description}</p>
                    </div>
                    <div className="when_where">
                        <h4>Date &#38; Time</h4>
                        <div className="date_location">
                            <p className="date_time">{moment(eventData.startDate).format("DDDD, MMM d,YYYY at H:mm")}</p>
                            <p className="location">{eventData.location.name}</p>
                        </div>
                        <div className="button_wrapper">
                            <Button text="Share" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="description_location">
                <h3>Description</h3>
                <div className="content">
                    <div className="description">{eventData.longDescription}</div>
                    <div className="location">
                        <h4>Evet Location</h4>
                        <div className="map_wrapper">
                            <GoogleMap zoom={18} center={currentCoordinate} mapContainerClassName="map_container">
                                <MarkerF position={currentCoordinate} />
                            </GoogleMap>
                        </div>
                    </div>
                </div>
            </div>
            <MiniEventList title={"Related Events"} />
            <FooterLocation />
            <Footer />
        </div>
    );
}
