import React from "react";
import MiniEventList from "../../Components/Events/MiniEventList";
import Footer from "../../Components/Footer/Footer";
import FooterLocation from "../../Components/FooterLocation/FooterLocation";
import Testimonials from "../../Components/Testimonials/Testimonials";
import HomeCollage from "./HomeCollage";
import HomeExperience from "./HomeExperience";
import HomeHighlight from "./HomeHighlight";
import HomeMenu from "./HomeMenu";
import HomeSpecialties from "./HomeSpecialties";

export default function Home() {
    return (
        <>
            <HomeCollage />
            <HomeExperience />
            <HomeHighlight />
            <HomeSpecialties />
            <Testimonials />
            <HomeMenu />
             <MiniEventList title={"Chaka Updates"}/>
      <FooterLocation />
      <Footer />
        </>
    );
}
