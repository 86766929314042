import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import item_1 from "../../Assets/menu-item-1.png";
import item_2 from "../../Assets/menu-item-2.png";
import item_3 from "../../Assets/menu-item-3.png";
import item_4 from "../../Assets/menu-item-4.png";
import item_5 from "../../Assets/menu-item-5.png";
import item_6 from "../../Assets/menu-item-6.png";

const images = [item_1, item_2, item_3, item_4, item_5, item_6];

const items = [0, 1, 2, 3, 4, 5].map((i) => {
    return (
        <div key={i} className="item">
            <img src={images[i]} />
            <div className="content">
                <p className="content_title">YECHAKA SPECIAL OMELET</p>
                <p className="description">Lorem ipsum dolor sit amet consectetur. Volutpat elit lectus porta volutpat quisque.</p>
            </div>
        </div>
    );
});

export default function HomeMenu() {
    const [currentIndex, setCurrentIndex] = useState(0);

    const onSwipeChange = (index, item) => {
        setCurrentIndex(index);
    };

    return (
        <div className="home_menu">
            <div className="title">
                <h2>Best Offers</h2>
            </div>
            <div className="menu">
                <div className="categories">
                    <ul>
                        <li className={currentIndex == 0 ? "active" : ""} onClick={() => setCurrentIndex(0)}>
                            Breakfast
                        </li>
                        <li className={currentIndex == 1 ? "active" : ""} onClick={() => setCurrentIndex(1)}>
                            Lunch
                        </li>
                        <li className={currentIndex == 2 ? "active" : ""} onClick={() => setCurrentIndex(2)}>
                            Deserts
                        </li>
                        <li className={currentIndex == 3 ? "active" : ""} onClick={() => setCurrentIndex(3)}>
                            Drinks
                        </li>
                    </ul>
                </div>
                <div className="items_container">
                    <Carousel
                        selectedItem={currentIndex}
                        emulateTouch={true}
                        showArrows={false}
                        showThumbs={false}
                        swipeable={true}
                        showStatus={false}
                        showIndicators={false}
                        useKeyboardArrows={true}
                        onChange={onSwipeChange}
                    >
                        {[0, 1, 2, 3].map((i) => {
                            return (
                                <div key={i} className="items">
                                    {items}
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        </div>
    );
}
