import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import Button from "../../Components/Common/Button";

import collageImage from "../../Assets/Collage-mini.png";

const items = [0, 1, 2, 3].map((i) => {
    return (
        <div key={i} className="c_item">
            <div className="message">
                <div className="mini_message">
                    <p>Chase The New Flavour</p>
                </div>
                <div className="title">
                    <h2>The key to Fine dining</h2>
                </div>
                <div className="description">
                    <p>
                        Sit tellus lobortis sed senectus vivamus molestie. Condimentum volutpat morbi facilisis quam scelerisque sapien. Et,
                        penatibus aliquam amet tellus{" "}
                    </p>
                </div>
                <a href="/menu">
                    <Button text={"Explore Menu"} method={() => {}} args={[]} />
                </a>
            </div>
            <div className="image_wrapper">
                <img src={collageImage} />
            </div>
        </div>
    );
});

export default function HomeCollage() {
    return (
        <div className="home_collage">
            <div className="phone">
                <a href="tel:+251 91000 2233">+251 91000 2233</a>
            </div>
            <div className="carousel">
                <Carousel
                    emulateTouch={true}
                    showArrows={false}
                    showThumbs={false}
                    swipeable={true}
                    showStatus={false}
                 >
                    {items}
                </Carousel>
            </div>
        </div>
    );
}
