import React from "react";

export default function ContactUs() {
    return (
        <div className="contact_us">
            <div className="left">
                <div className="title">
                    <h2>Get In Touch</h2>
                    <p>The Freshest Ingredients For You Every Day</p>
                </div>
                <form className="form">
                    <input className="name" placeholder="Your Name" />
                    <input className="phone" placeholder="Your Phone Number" type="number"/>
                    <textarea className="message" placeholder="Message" />
                </form>
            </div>
            <div className="right">
                <div className="hours">
                    <h3>Working Hours</h3>
                    <p>
                        Monday-Friday: <br></br>07:00 am - 12:00 am
                    </p>
                    <p>
                        Monday-Friday: <br></br>07:00 am - 12:00 am
                    </p>
                </div>
                <div className="contacts">
                    <h3>Contact Us</h3>
                    <p>+251 909-344-1230</p>
                    <p>+251 909-555-1230</p>
                </div>
            </div>
        </div>
    );
}
