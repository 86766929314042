import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import testimonial from "../../Assets/testimonial-1.png";

const items = [0, 1, 2, 3].map((i) => {
    return (
        <div key={i} className="c_item">
            <div className="testimonial_wrapper">
                <div className="image_wrapper">
                    <img src={testimonial} />
                </div>
                <div className="content">
                    <p className="name">Justin Philips {i}</p>
                    <p className="testimony">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas.
                        Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit
                        cursus pellentesque enim arcu.
                    </p>
                </div>
            </div>
        </div>
    );
});

export default function Testimonials() {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const nextImage = () => setCurrentImageIndex(currentImageIndex + 1 > items.length - 1 ? 0 : currentImageIndex + 1);
    const prevImage = () => setCurrentImageIndex(currentImageIndex - 1 < 0 ? items.length - 1 : currentImageIndex - 1);

    return (
        <div className="testimonials">
            <div className="static_content">
                <div className="title">
                    <p>What Customers Say</p>
                </div>
                <div className="button_wrapper">
                    <button className="gallery_previous" onClick={prevImage} type="button"></button>
                    <button className="gallery_next" onClick={nextImage} type="button"></button>
                </div>
            </div>
            <Carousel
                selectedItem={currentImageIndex}
                emulateTouch={true}
                showArrows={false}
                showThumbs={false}
                swipeable={true}
                showStatus={false}
                showIndicators={false}
                infiniteLoop={true}
                useKeyboardArrows={true}
            >
                {items}
            </Carousel>
        </div>
    );
}
