import React from 'react'
import Button from '../../Components/Common/Button'

export default function LocationItem({id, img, name, description, className}) {
    console.log(id)
  return (
    <div className={`${className} location_item`}>
        <div className='image_title_wrapper'>
            <img src={img} />
            <h3>{name}</h3>
        </div>
        <div className='desc_link_wrapper'>
            <p>{description}</p>
            <a href={`/location/${id}`}>
            <Button text={"View More"} /></a>
        </div>
    </div>
  )
}
