import React from "react";
import Button from "../../Components/Common/Button";

import img_1 from "../../Assets/food-img-1-1.png";
import img_2 from "../../Assets/food-img-1-2.png";

export default function HomeExperience() {
    return (
        <div className="home_experience">
            <div className="title">
                <h2>The Perfect Place For An Exceptional Experience</h2>
            </div>

            <div className="content">
                
                <img className="img-1" src={img_1} />
                <img className="img-2" src={img_2} />
                <div className="description">
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Adipiscing aenean eget adipiscing magna. Cursus placerat aliquam duis leo
                        ultrices ac amet. Ornare amet risus ipsum faucibus et a. Arcu scelerisque eget massa porta turpis massa tellus
                        dictum. Aliquet neque tortor elementum dictum commodo pharetra ac enim. Elit ornare sit dolor eleifend. Amet donec
                        sollicitudin ac ultrices sed. Velit cursus a diam tellus amet. Aliquet tincidunt diam ipsum vitae adipiscing
                        dignissim. Sed quisque tempor vel egestas ultrices dignissim varius lacus volutpat. Ac at ullamcorper nibh auctor.
                        In quis vel in et volutpat est vulputate. Eleifend ut ipsum tortor risus. Vitae convallis id nulla posuere lacinia
                        at posuere cursus elit. Leo nisi pretium ultricies eu convallis congue posuere arcu facilisis. Sit nam velit.
                    </p>
                    <a href="/about-us">
                        <Button text={"Know More"} method={() => {}} args={[]} />
                    </a>
                </div>
            </div>
        </div>
    );
}
