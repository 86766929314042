import React from "react";

import img_1 from "../../Assets/food-img-2-1.png";
import img_2 from "../../Assets/food-img-2-2.png";
import Button from "../../Components/Common/Button";

export default function HomeHighlight() {
    return (
        <div className="home_highlight">
            <img className="img_1" src={img_1} />
            <div className=" content content_1">
                <h3>Lunch, Dinner Or Both?</h3>
                <div className="description">
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Adipiscing aenean eget adipiscing magna. Cursus placerat aliquam duis leo
                        ultrices ac amet. Ornare amet risus ipsum faucibus et a. Arcu scelerisque eget massa porta turpis massa tellus
                        dictum. Aliquet neque tortor elementum dictum commodo pharetra ac enim. Elit ornare sit dolor eleifend. Amet donec
                        sollicitudin ac ultrices sed. Velit cursus a diam tellus amet. Aliquet tincidunt diam ipsum vitae adipiscing
                        dignissim. Sed quisque tempor vel
                    </p>
                </div>
                <Button text={"Know More"} method={() => {}} args={[]} />
            </div>
            <img className="img_2" src={img_2} />
            <div className="content content_2">
                <h3>Taste The Difference</h3>
                <div className="description">
                    <p>
                        Lorem ipsum dolor sit amet consectetur. Adipiscing aenean eget adipiscing magna. Cursus placerat aliquam duis leo
                        ultrices ac amet. Ornare amet risus ipsum faucibus et a. Arcu scelerisque eget massa porta turpis massa tellus
                        dictum. Aliquet neque tortor elementum dictum commodo pharetra ac enim. Elit ornare sit dolor eleifend. Amet donec
                        sollicitudin ac ultrices sed. Velit cursus a diam tellus amet. Aliquet tincidunt diam ipsum vitae adipiscing
                        dignissim. Sed quisque tempor vel
                    </p>
                </div>
                <Button text={"Know More"} method={() => {}} args={[]} />
            </div>
        </div>
    );
}
