import React from "react";
import moment from "moment/moment";

import img_1 from "../../Assets/update-1.png";
import img_2 from "../../Assets/update-2.png";
import img_3 from "../../Assets/update-3.png";
import EventItem from "./EventItem";


const data = [
    {
        id : 1,
        img : img_1,
        title : "Lorem ipsum dolor sit amet consectetur.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate : moment(),
        endDate : moment()
    },
    {
        id : 2,
        img : img_2,
        title : "Lorem ipsum dolor sit amet consectetur.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate : moment().add(100, "day"),
        endDate : moment()
    },
    {
        id : 3,
        img : img_3,
        title : "Lorem ipsum dolor sit amet consectetur.",
        description : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Volutpat mattis ipsum turpis elit elit scelerisqu egestas mus in.",
        startDate : moment().add(299, "day"),
        endDate : moment()
    },
]

// later export default function MiniEventList({title, data}) {
export default function MiniEventList({title}) {
    return (
        <div className="mini_events">
            <div className="title">
                <h2>{title}</h2>
            </div>
            <div className="items">
                {data.map((event, i) => {
                    return <EventItem key={i} {...event} />
                })}
            </div>
        </div>
    );
}
