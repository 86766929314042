import React from "react";

export default function EventItem({ id, img, title, description, startDate, endDate }) {
    return (
        <a href={`events/${id}`} className="event_item">
            <img src={img} />
            <div className="content">
                <div className="title_date">
                    <div className="date_wrapper">
                        <p className="month">{startDate.format("MMM")}</p>
                        <p className="date">{startDate.format("D")}</p>
                    </div>
                    <p className="item_title">{title}</p>
                </div>

                <p className="item_description">{description}</p>
                <button>Read More</button>
            </div>
        </a>
    );
}
