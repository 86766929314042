import React, { useState } from "react";

import logo from "../../Assets/logo.png";
import fb from "../../Assets/fb-menu.png";
import ins from "../../Assets/in-menu.png";
import tw from "../../Assets/tw-menu.png";
import menu_open from "../../Assets/menu-open.png";
import { useLocation } from "react-router-dom";
import { routeObject } from "../../router";

export default function MainMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const location = window.location.pathname;

    return (
        <div className="main_menu">
            <div className="sidebar">
                <div className="logo">
                    <a href="/">
                        <img src={logo} />
                    </a>
                </div>
                <div className="social_media">
                    <a href="#fb">
                        <img src={fb} />
                    </a>
                    <a href="#tw">
                        <img src={tw} />
                    </a>
                    <a href="#ln">
                        <img src={ins} />
                    </a>
                </div>
                <div className={`menu_btn ${isMenuOpen ? "active" : ""}`}>
                    <button onClick={() => setIsMenuOpen(!isMenuOpen)}>Open / Close Menu</button>
                </div>
            </div>
            <div className={`nav ${isMenuOpen ? "active" : ""}`}>
                <ul>
                    {routeObject
                        .filter((route) => route.isMainMenu)
                        .map((route, i) => {
                            return (
                                <li key={i}>
                                    <a href={route.path} className={`${route.path == location ? "active" : ""}`}>
                                        {route.name}
                                    </a>
                                </li>
                            );
                        })}
                </ul>
            </div>
        </div>
    );
}
